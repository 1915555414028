<template>
  <div class="travel-plan-cell">
    <van-row @click="to(data.to)" class="card" type="flex" justify="center" align="center">
      <van-col offset="1" span="5" class="progress">
        {{ data.modifiedDate }}
        <i v-if="data.line " class="line" :style="{height: data.titleName.length>0?'25px':'15px'}"></i>
      </van-col>
      <van-col span="9">
        <van-row>
          <van-col offset="1" span="23" class="text van-ellipsis title">{{ data.titleName }}</van-col>
        </van-row>
        <van-row>
          <van-col offset="1" span="13" class="text info">
            {{ data.modifiedDate }}
          </van-col>
          <van-col offset="1" span="9" class="text van-ellipsis info">
            {{ data.createdPeople }}
          </van-col>
        </van-row>
      </van-col>
      <van-col span="9">
        <van-swipe-cell style="height: 100%">
          <van-row class="right">
            <van-col span="16">
              <div class="status" :style="'color:'+map[data.ykbStatus]">{{ data.ykbStatus }}</div>

            </van-col>
            <van-col span="8">
              <van-icon name="arrow" class="icon" color="#C0BFCE"/>
            </van-col>
          </van-row>
          <template slot="right">
            <van-button style="height: 100%; width: 100%;" square type="danger"
                        @click="deleteList(data)" text="删除"/>
          </template>
        </van-swipe-cell>
      </van-col>

    </van-row>
  </div>
</template>

<script>
import '@vant/touch-emulator';
export default {
  name: "index",
  data() {
    return {
      map: {
        '已审核': '#1AAD19',
        '未审核': '#F00707',
        '审核中': '#318EF5',
      }
    }
  },
  methods: {
    to(to) {
      this.$router.push(to);
    },
    deleteList(item) {
      console.log(item)
      if (item.ykbStatus == '未审核') {
        this.$ajax.post('/api/ajax/planVisit/deleteAllBulkCrmPlanvisit.json',
            {
              titleId: item.titleId
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('删除成功！');
            //重新初始化查询
            setTimeout(() => {
              this.$emit('rtnInit', item)
            }, 200);
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('删除失败！');
        })
      } else {
        this.$message.warning('当前状态不允许删除！');
      }
    }
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.travel-plan-cell {
  background: #ffffff;
  margin-top: 4px;
}

.travel-plan-cell .card {
  padding-top: 5px;
}

.travel-plan-cell .progress {
  position: relative;
  font-weight: 700;
  font-size: 12px;
  color: #101010;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.travel-plan-cell .progress i.line:nth-last-of-type(1) {
  display: block;
  position: absolute;
  height: 30px;
  border-left: 1px solid;
  left: 50%;
  color: #5b6b73;
}


.travel-plan-cell .text {
  text-align: left;
}

.travel-plan-cell .title {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.travel-plan-cell .info {
  font-weight: 400;
  font-size: 12px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.travel-plan-cell .icon {
  line-height: 24px;
}
</style>