<template>
  <div class="bulk-crms-travel-plan-list">
    <div class="title">
      <!-- 搜索框-->
      <van-search v-model="createdName" placeholder="请输入负责人名称" @cancel="onCancel()" show-action/>

      <van-row style="margin-top: -10px; margin-left: -10px">
        <van-col span="7" offset="1">
          <el-select v-model="zflx" placeholder="走访类型">
            <el-option
                v-for="item in left_list"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </van-col>
        <van-col span="7" offset="0">
          <el-select v-model="zfzt" placeholder="走访状态">
            <el-option
                v-for="item in right_list"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </van-col>
        <van-col span="7" offset="1">

        </van-col>
      </van-row>

    </div>


    <!-- 列表-->
    <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 45px"
          >
            <travel-plan-cell v-for="item in list" v-bind:key="item.id" @rtnInit='rtnInit' :data="item"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 添加按钮-->
    <Add to="/BulkCrmsTravelPlanAdd/" :param=Math.random().toString(36).slice(2)></Add>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import '@vant/touch-emulator';
import CrmTabBar from "@/components/CrmTabBar";
import TravelPlanCell from "@/components/TravelPlanCell/index";
import Add from '@/components/Add';
import {Toast} from 'vant';

export default {
  name: "BulkCrmsTravelPlanList",
  components: {CrmTabBar, TravelPlanCell, Add, Toast},
  data() {
    return {
      list: [],
      createdName: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      currentPage: 1, // 当前页码
      pageSize: 25, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      time: 0,
      zflx: '',
      left_list: [
        { text: '全选', value: '000' },
        { text: '客户回访推送', value: "('002','004')" },
        { text: '走访计划', value: "('001','003')" },
      ],
      zfzt: '',
      right_list: [// 右侧菜单 选项数组
        { text: '全选', value: '000' },
        { text: '未审核', value: '00' },
        { text: '审核中', value: '01' },
        { text: '已审核', value: '02' },
      ]
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh();
  },
  watch: {
    //监听负责人名头
    'createdName': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听走访类型下拉
    'zflx': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听走访状态下拉
    'zfzt': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    onCancel() {
      this.createdName = ''
      this.zflx = ''
      this.zfzt = ''
      this.onRefresh();
    },
    rtnInit(data) {
      this.onRefresh();
    },
    // 下拉刷新
    getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.loading = true
      this.$ajax.post('/api/ajax/planVisit/getDisPlanvisitList.json',
          {
            createdPeople: this.$store.getters.userId,
            titleId: '',
            createdName: this.createdName,
            travelPlanid: '',
            travelType: this.zflx,
            ykbStatus: this.zfzt,
            current: this.currentPage,
            size: this.pageSize,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            // this.list[this.list.length].line = true;
            this.list = []
            this.refreshing = false
          }
          this.time++
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
          console.log(23 * this.time)
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].to = '/BulkCrmsTravelPlanDetail/' + this.list[i].titleId
            this.list[i].line = true;
            if (i == this.list.length - 1) {
              this.list[i].line = false;
            }
          }
          Toast.success('加载成功');
          console.log(this.list)
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    handleCommand(command) {
    }
  }
}
</script>

<style scoped>
.bulk-crms-travel-plan-list .title{
  background-color: #FFFFFF;
}
.bulk-crms-travel-plan-list .el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.bulk-crms-travel-plan-list .el-icon-arrow-down {
  font-size: 12px;
}

</style>

<style>
.bulk-crms-travel-plan-list .el-input__inner{
  border: none;
}
</style>